<template>
    <div v-loading="loading">
        <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 30px">
            <div style="width: 30%;font-family: 新宋体;">*一级奖励(%)</div>
            <div style="width: 30%;font-family: 新宋体;">*二级奖励(%)</div>
            <div style="width: 30%;font-family: 新宋体;">*三级奖励(%)</div>
        </div>

        <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 10px">
            <div style="width: 30%">
                <el-input v-model="rewardList[0].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入一级奖励"></el-input>
            </div>
            <div style="width: 30%">
                <el-input v-model="rewardList[1].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入二级奖励"></el-input>
            </div>
            <div style="width: 30%">
                <el-input v-model="rewardList[2].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入三级奖励"></el-input>
            </div>
        </div>

        <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 30px">
            <div style="width: 30%;font-family: 新宋体;">*用户注册</div>
            <div style="width: 30%;font-family: 新宋体;">*最小充值金额</div>
            <div style="width: 30%;font-family: 新宋体;">*最大充值金额</div>
        </div>

        <div style="display: flex;justify-content: space-between;width: 70%;padding-top: 10px">
            <div style="width: 30%">
                <el-input v-model="rewardList[3].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入用户注册奖励"></el-input>
            </div>
            <div style="width: 30%">
                <el-input v-model="rewardList[4].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入最小充值金额"></el-input>
            </div>
            <div style="width: 30%">
                <el-input v-model="rewardList[5].rewardNum" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width: 200px" clearable
                          placeholder="请输入最大充值金额"></el-input>
            </div>
        </div>

        <div style="padding-top: 50px">
            <el-button type="primary" @click="rewardEdit">保存设置</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RewardSet",
        data() {
            return {
                loading: false,
                rewardList: [],
            }
        },
        methods: {
            rewardEdit(){
                this.axios.post('/system/editReward',this.rewardList).then(resp=>{
                    if (resp.data.code==200){
                        this.$message.success(resp.data.message)
                        this.findAll()
                    }
                })
            },
            //获取当前奖励大小
            findAll() {
                this.loading = true
                this.axios.get('/system/findReward').then(resp => {
                    if (resp.data.code == 200) {
                        this.rewardList = resp.data.data
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {
            this.findAll()
        }
    }
</script>

<style scoped>

</style>
